import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { DateTimeFormat } from '../../services';
import { MUIGridState, ReportDateRange, ReportDateRangeDisplayLookup, ReportUX } from '../../shared/types';
import momentTz from 'moment-timezone';
import { formatPrice } from '../../shared/goods_and_services/pricing';

export const areMUIConfigsEqual = (params: {
    savedMUI: MUIGridState | null | undefined;
    dirtyMUI: MUIGridState | null | undefined;
}) => {
    const { savedMUI, dirtyMUI } = params;
    const colVizChanged = !isEqual(dirtyMUI?.columns?.columnVisibilityModel, savedMUI?.columns?.columnVisibilityModel);
    // not tracking column size until we can fix the bug where column size resets after columns rerender
    //     due to useMemo dependencies
    const colSizeChanged = false;
    // const colSizeChanged = !isEqual(
    //     mapValues(dirtyMUI?.columns?.dimensions, (val) => val?.width),
    //     mapValues(savedMUI?.columns?.dimensions, (val) => val?.width),
    // );
    const colOrderChanged = !isEqual(dirtyMUI?.columns?.orderedFields, savedMUI?.columns?.orderedFields);
    const filtersChanged = !isEqual(dirtyMUI?.filter, savedMUI?.filter);
    const sortingChanged = !isEqual(dirtyMUI?.sorting, savedMUI?.sorting);
    const pinnedColumnsChanged = !isEqual(dirtyMUI?.pinnedColumns, savedMUI?.pinnedColumns);
    const rowGroupingChanged = !isEqual(dirtyMUI?.rowGrouping, savedMUI?.rowGrouping);
    const aggregationChanged = !isEqual(dirtyMUI?.aggregation, savedMUI?.aggregation);
    return {
        isEqual:
            !colVizChanged &&
            !colSizeChanged &&
            !colOrderChanged &&
            !filtersChanged &&
            !sortingChanged &&
            !pinnedColumnsChanged &&
            !rowGroupingChanged &&
            !aggregationChanged,
        colVizChanged,
        colSizeChanged,
        colOrderChanged,
        filtersChanged,
        sortingChanged,
        pinnedColumnsChanged,
        rowGroupingChanged,
        aggregationChanged,
    };
};

export const areReportConfigsEqual = (params: { saved: ReportUX | null; dirty: ReportUX | null }): boolean => {
    const { saved, dirty } = params;
    if (!saved || !dirty) {
        return true;
    }
    const { mui_config: savedMUI, ...restOfSaved } = saved;
    const { mui_config: dirtyMUI, ...restOfDirty } = dirty;

    // check MUI config first
    if (!areMUIConfigsEqual({ savedMUI, dirtyMUI }).isEqual) {
        return false;
    }
    return isEqual({
        ...restOfSaved,
        custom_from_date: restOfSaved.custom_from_date ? moment(restOfSaved.custom_from_date).toISOString() : null,
        custom_to_date: restOfSaved.custom_to_date ? moment(restOfSaved.custom_to_date).toISOString() : null,
    }, {
        ...restOfDirty,
        custom_from_date: restOfDirty.custom_from_date ? moment(restOfDirty.custom_from_date).toISOString() : null,
        custom_to_date: restOfDirty.custom_to_date ? moment(restOfDirty.custom_to_date).toISOString() : null,
    });
};

export const getReportDateRangeDisplayText = (params: {
    dateRange: ReportDateRange;
    customFromDate: moment.Moment | Date | null;
    customToDate: moment.Moment | Date | null;
}): string => {
    const { dateRange, customFromDate, customToDate } = params;
    if (dateRange === ReportDateRange.custom && customFromDate && customToDate) {
        return `${moment(customFromDate).format(DateTimeFormat.StandardDate)} - ${moment(customToDate).format(
            DateTimeFormat.StandardDate,
        )}`;
    } else {
        return ReportDateRangeDisplayLookup('', dateRange).text;
    }
};

export const getCellRenderer = <T extends GridValidRowModel>(
    renderer: (params: GridRenderCellParams<T>) => JSX.Element | null,
) => {
    return (params: GridRenderCellParams<T>) => {
        // this will return the value when grouping by the row rather than the cell renderer output
        // this is the value returned by valueGetter() (if defined for the column)
        // if you don't do this the grouped row will show nothing when it should show the grouped by value
        if (params.rowNode.type === 'group') {
            return params.value;
        }
        return renderer(params);
    };
};

export const getGoodsAndServicesCellRenderer = <T extends GridValidRowModel>(
    renderer: (params: GridRenderCellParams<T>) => JSX.Element | null,
) => {
    return (params: GridRenderCellParams<T>) => {
        if (params.rowNode.type === 'pinnedRow') {
            return params.value;
        }
        return renderer(params);
    };
};

export const formatDateValue = (date: Date | null | string, timeZone: string) => {
    if (!date) {
        return '';
    }

    return momentTz(date).tz(timeZone).format('DD MMM YYYY');
};

export const getCompleteDateDetails = (date: Date | null, timeZone: string) => {
    if (!date) {
        return '';
    }
    return momentTz(date).tz(timeZone).format('dddd, MMMM Do, YYYY @ h:mm:ss a z');
};

export const formatTimeValue = (date: Date | null, timeZone: string) => {
    if (!date) {
        return '';
    }

    return momentTz(date).tz(timeZone).format('h:mm a z');
};

export const formatPriceForReports = (price: number, assetType: string, format?: string) =>
    isNaN(price) ? '' : formatPrice(price, assetType, format);
;