import {
    GatherCaseUX,
    LocationUX,
    CloudinarySignatureContext,
    GatherCaseRecord,
    LongAddress,
    FeatureKey,
    Features,
    PaymentMethod,
    PaymentMode,
} from '../types';
import { US_STATES } from '../locale';
import moment from 'moment-timezone';

import momentTz from 'moment-timezone';

export * from './case';
export * from './funeralHome';
export * from './task';

export enum DateTimeFormat {
    ShortDate = 'YYYY-MM-DD',
    ShortDateWithTime = 'MM/DD/YYYY [at] h:mma',
    ShortDateWithTimeAndTZ = 'MM/DD/YYYY [at] h:mma z',
    LongDate = 'dddd, DD MMM YYYY',
    LongDateWithTime = 'dddd, DD MMM YYYY [at] h:mma',
    LongDateWithTimeAndTZ = 'dddd, DD MMM YYYY [at] h:mm a z',
    StandardDate = 'MM/DD/YYYY',
    FilenameTimestamp = 'YYYYMMDDHHmmSS',
    MonthYear = 'MM-YYYY',
    DayMonthYear = 'DD MMM YYYY',
    DayMonthYearWithTime = 'DD MMM YYYY h:mm a z',
    MonthDayYear = 'MMM Do, YYYY',
}

export interface SupportedStringTemplateClasses {
    case?: GatherCaseUX | Partial<GatherCaseUX> | GatherCaseRecord;
}

/**
 * This will replace all string templates found in the `text` string.
 * A string template is defined as {{class.field}} where `class` is defined in `supportedTemplateClasses`
 * and `field` is a field for that `class`. `supportedTemplateClasses` keys must map to objects where the
 * `field` can be found, if `field` is not found on the object then an empty string is used.
 */
export const replaceStringTemplates = (
    text: string,
    supportedTemplateClasses: SupportedStringTemplateClasses,
): string => {
    let convertedText = text;
    Object.keys(supportedTemplateClasses).forEach((c) => {
        // /{{className.(\w+)}}/
        const classRegex = new RegExp(`{{${c}\\.(\\w+)}}`, 'g');
        let match = classRegex.exec(convertedText);
        const matchingFields: string[] = [];
        while (match) {
            if (matchingFields.indexOf(match[1]) === -1) {
                matchingFields.push(match[1]);
            }
            match = classRegex.exec(convertedText);
        }
        // for each matching field replace all instances of that template with the correct value
        matchingFields.forEach((field: string) => {
            convertedText = convertedText.replace(
                new RegExp(`{{${c}\\.${field}}}`, 'g'), // /{{className.field}}/
                supportedTemplateClasses[c] && supportedTemplateClasses[c][field]
                    ? supportedTemplateClasses[c][field] : '',
            );
        });
    });

    return convertedText;
};

export const hasLocationChanged = (loc1: LocationUX, loc2: LocationUX): boolean => {
    return loc1.name !== loc2.name
        || loc1.address.address1 !== loc2.address.address1
        || loc1.address.address2 !== loc2.address.address2
        || loc1.address.city !== loc2.address.city
        || loc1.address.state !== loc2.address.state
        || loc1.address.postal_code !== loc2.address.postal_code
        || loc1.address.timezone !== loc2.address.timezone
        ;
};

export const cloudinaryContextToStr = (context: CloudinarySignatureContext): string =>
    Object.keys(context).map((key) => `${key}=${JSON.stringify(context[key])}`).join('|');

export const removeNonDigits = (str: string): string => {
    return str.replace(/\D/g, '');
};

// remove non-ascii characters from string for use in S3 filenames
// Sanitize string for use in S3 filenames and other things that don't like non-ascii characters
// https://stackoverflow.com/a/20856346/9437228
// aliases: sanitizeFileName, sanitizeDownload
export const removeNonAscii = (str: string): string => {
    return str.replace(/[^\x00-\x7F]/g, "");
};

const VALID_US_PHONE_REGEX = /^1?([2-9]{1}\d{2})(\d{3})(\d{4})$/;

/**
 * 
 * @param phone number to test
 * @returns true if phone is valid, false otherwise
 */
export const isValidPhoneNumber = (phone: string | null) => {
    return phone !== null && VALID_US_PHONE_REGEX.test(removeNonDigits(phone));
};

export const getPhoneNumberParts = (phone: string) => {
    const phoneDigits = removeNonDigits(phone);
    const groups = phoneDigits.match(VALID_US_PHONE_REGEX);
    if (!groups) {
        return null;
    }
    return {
        areaCode: groups[1],
        prefix: groups[2],
        subscriber: groups[3],
    };
};

const VALID_EMAIL_REGEX = new RegExp([
    /^[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*@/,
    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
].map(r => r.source).join(''));

/**
 * 
 * @param email to test
 * @returns true if email is valid, false otherwise
 */
export const isValidEmail = (email: string): boolean => {
    return VALID_EMAIL_REGEX.test(email);
};

const VALID_POSTAL_CODE = new RegExp(/^\d{5}(-\d{4})?$/);
/**
 * 
 * @param email to test
 * @returns true if email is valid, false otherwise
 */
export const isValidPostalCode = (postalCode: string): boolean => {
    return VALID_POSTAL_CODE.test(postalCode);
};

const VALID_URL =
    new RegExp(
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,23}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    );

export const isValidURL = (url: string): boolean => {
    return VALID_URL.test(url);
};

const VALID_GATHERSITE_URL =
    new RegExp(
        /^https:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,23}\//
    );

export const isValidGatherWebsiteURL = (url: string): boolean => {
    return VALID_GATHERSITE_URL.test(url);
};

export const websiteUrlWithSlash = (url: string): string => {
    return url.endsWith('/') ? url : `${url}/`;
};

const VALID_CASE_NAME = new RegExp(/^[a-z0-9\-àáâãäåæçèéêëìíîïðñòóôõöùúûüý]+$/);

export const isValidCaseName = (caseName: string): boolean => {
    return VALID_CASE_NAME.test(caseName);
};

const VALID_IP_ADDRESS_REGEX = new RegExp(['^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.',
    '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'].join(''));

export const isValidIpAddress = (ipAddress: string): boolean => {
    return VALID_IP_ADDRESS_REGEX.test(ipAddress);
};

/**
 * Reduces an array of items to an array of a specific field and removes items that do not have the specific field
 *
 * @param list array of items to map & filter
 * @param mapFunction function to determine what field is pulled off each item
 */
export const cleanMap = <T, U>(
    list: T[],
    mapFunction: (t: T) => U | undefined | null,
): U[] => {
    return list.reduce(
        (returnList, t) => {
            const u = mapFunction(t);
            if (u !== null && u !== undefined) {
                return [...returnList, u];
            }
            return returnList;
        },
        [] as U[],
    );
};

type NameParts = {
    fname: string;
    mname: string;
    lname: string;
};

type NamePartsOption = {
    fname?: string | null;
    mname?: string | null;
    lname?: string | null;
    suffix?: string | null;
};

export function splitFullName(fullName?: string | null): NameParts {
    const nameArray = (fullName || '').trim().split(/\s+/);
    const fname = nameArray.shift() || '';
    const lname = nameArray.pop() || '';
    const mname = nameArray.length > 0 ? nameArray.join(' ') : '';
    return { fname, mname, lname };
}

export function joinNameParts(nameObj: Partial<NamePartsOption>): string {
    const fullName = [
        nameObj.fname && nameObj.fname.trim() !== '' ? nameObj.fname.trim() : null
        , nameObj.mname && nameObj.mname.trim() !== '' ? nameObj.mname.trim() : null
        , nameObj.lname && nameObj.lname.trim() !== '' ? nameObj.lname.trim() : null
        , nameObj.suffix && nameObj.suffix.trim() !== '' ? nameObj.suffix.trim() : null
    ].filter((obj) => obj).join(' ');
    return fullName;
}

export const areCaseInsensitiveEqual = (str1: string | null, str2: string | null) => {
    return str1 !== null && str2 !== null && str1.toLowerCase() === str2.toLowerCase();
};

export const areEmailsEqual = (email1: string | null, email2: string | null) => {
    return areCaseInsensitiveEqual(email1, email2);
};

export const getStateAbbreviation = (state: string): string | null => {
    if (state.length === 2) {
        return state;
    } else {
        const match = US_STATES.find((s) => areCaseInsensitiveEqual(s.name, state));
        return match ? match.abbreviation : null;
    }
};

export const longAddressToAddressString = (longAddress: LongAddress): string => {
    let addressString: string = longAddress.address1;

    if (longAddress.address2) {
        addressString = `${addressString}, ${longAddress.address2}`;
    }

    addressString = `${addressString} ${longAddress.city}, ${longAddress.state} ${longAddress.postalCode}`;

    return addressString;
};

export const getStateLongName = (state: string): string | null => {
    if (state.length > 2) {
        return state;
    } else {
        const match = US_STATES.find((s) => areCaseInsensitiveEqual(s.abbreviation, state));
        return match ? match.name : null;
    }
};

export const randomInteger = () => Math.floor(Math.random() * 1000);

export const randomDecimal = () => Math.random() * 1000;

export function randomIntegerInRange(min: number = 0, max: number = 9999) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const convertStrToIntegerOrNull = (intAsStr: string | null): number | null => {
    if (!intAsStr) {
        return null;
    }

    const strAsInt = parseInt(intAsStr, 10);
    if (Number.isNaN(strAsInt)) {
        return null;
    } else {
        return strAsInt;
    }
};

export const convertStrToInteger = (intAsStr: string | null): number => {
    return convertStrToIntegerOrNull(intAsStr) || 0;
};

export interface PasswordCheckResult {
    valid: boolean;
    errorMessage: string[];
}

export const isValidPassword = (val: string): PasswordCheckResult => {
    // some basic password validatation
    const result: PasswordCheckResult = {
        valid: true,
        errorMessage: [],
    };
    const MIN_LENGTH = 6;
    const MAX_LENGTH = 100;
    const MIN_LOWERCASE = 1;
    const MIN_UPPERCASE = 0;
    const MIN_NUMERIC = 1;
    const MIN_SPECIALCHAR = 0;

    if (val.length < MIN_LENGTH) {
        result.valid = false;
        result.errorMessage.push(`Your password should be more than ${MIN_LENGTH} characters`);
    }
    if (val.length > MAX_LENGTH) {
        result.valid = false;
        result.errorMessage.push(`Your password should be less than ${MAX_LENGTH} characters`);
    }
    const lowerCaseCount = (val.match(/[a-z]/g) || []).length;
    if (lowerCaseCount < MIN_LOWERCASE) {
        result.valid = false;
        result.errorMessage.push(`Requires at least ${MIN_LOWERCASE} lowercase letter`);
    }
    const upperCaseCount = (val.match(/[A-Z]/g) || []).length;
    if (upperCaseCount < MIN_UPPERCASE) {
        result.valid = false;
        result.errorMessage.push(`Requires at least ${MIN_UPPERCASE} UPPERCASE letter`);
    }
    const numericCount = (val.match(/[0-9]/g) || []).length;
    if (numericCount < MIN_NUMERIC) {
        result.valid = false;
        result.errorMessage.push(`Requires at least ${MIN_NUMERIC} number`);
    }
    const specialCount = (val.match(/[^0-9a-zA-Z]/g) || []).length;
    if (specialCount < MIN_SPECIALCHAR) {
        result.valid = false;
        result.errorMessage.push(`Requires at least ${MIN_SPECIALCHAR} or more instances of a special character`);
    }

    return result;
};

const ISO_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;
export const isValidISODateString = (dateString: string): boolean => !!ISO_DATE_REGEX.exec(dateString);

export const isDateRangeValid = (start: momentTz.Moment, end: momentTz.Moment): boolean => {
    return end.isAfter(start);
};

export const isAllUpperCase = (testString: string): boolean => testString === testString.toUpperCase();
export const isAllLowerCase = (testString: string): boolean => testString === testString.toLowerCase();

const yearMonthDayRegEx = /^((?:18|19|20)\d{2})[-\/](0\d|1[012])[-\/](0[123456789]|[12]\d|3[01])/;
const monthDayYearRegEx = /^(0\d|1[012])[-\/](0[123456789]|[12]\d|3[01])[-\/]((?:18|19|20)\d{2})/;
const dayMonthYearRegEx = /^(0[123456789]|[12]\d|3[01])[-\/](0\d|1[012])[-\/]((?:18|19|20)\d{2})/;
export const formatGatherDate = (dateTestString: string): string | null => {
    let expectedFormat;
    // should almost always match the first regex (yearMonthDay) as this is what comes from the input
    if (yearMonthDayRegEx.test(dateTestString)) {
        expectedFormat = DateTimeFormat.ShortDate;
    } else if (monthDayYearRegEx.test(dateTestString)) {
        expectedFormat = 'MM-DD-YYYY';
    } else if (dayMonthYearRegEx.test(dateTestString)) {
        expectedFormat = 'DD-MM-YYYY';
    } else {
        return null;
    }

    // Moment ignores non-alphanumeric characters in the format so / and - will both be accepted as valid
    const dateMoment = moment(dateTestString, expectedFormat);
    if (dateMoment.isValid()) {
        const formatted = dateMoment.format(DateTimeFormat.ShortDate);
        return formatted;
    }
    return null;
};

export const dateToString = (date: Date | string | null, format: string = 'DD MMM YYYY'): string => {
    if (date) {
        return momentTz(date).format(format);
    } else {
        return '';
    }
};

export const formatDateString = (date: string, format: string = 'DD MMM YYYY'): string => {
    if (date && date.trim && date.trim() !== '') {
        return dateToString(date, format);
    }
    return '';
};

export const getDOBandDOD = (
    activeCase: { dob_date: string | null; dod_start_date: string | null },
    format?: string,
    hideUnderline?: boolean,
) => {
    const dob = activeCase.dob_date
        && activeCase.dob_date !== '' ?
        formatDateString(activeCase.dob_date, format)
        : hideUnderline ? '' : `___`;
    const dod = activeCase.dod_start_date
        && activeCase.dod_start_date !== '' ?
        formatDateString(activeCase.dod_start_date, format)
        : hideUnderline ? '' : `___`;

    return { dob, dod };
};

export const lifeSpan = (
    activeCase: { dob_date: string | null; dod_start_date: string | null },
    format?: string,
    hideUnderline?: boolean,
): string => {
    const { dob, dod } = getDOBandDOD(activeCase, format, hideUnderline);
    const lifespan = dob || dod ? `${dob}${dob && dod ? ' - ' : ''}${dod}` : '';

    return lifespan;
};

export const pluralize = (
    word: string,
    count: number,
    pluralWord?: string,
) => {
    if (count === 1) {
        return word;
    } else {
        return pluralWord || `${word}s`;
    }
};

export const rememberPageTitle = (
    activeCase: { display_full_name: string; dob_date: string | null; dod_start_date: string | null },
    place: { city: string | null; state: string | null },
): string => {
    const lifespan = lifeSpan(activeCase, 'YYYY', true);
    const title = `${activeCase.display_full_name} Obituary${lifespan ? ` (${lifespan})` : ''}`;

    if (place.city && place.state) {
        return `${title} - ${place.city}, ${place.state}`;
    } else {
        return title;
    }
};

export const sleep = (ms: number) => new Promise(resolve => {
    setTimeout(resolve, ms);
});

// Await for up to timeoutSeconds and then throw an exception
// if the request promise fulfills prior to the timeout, then cancel the timer
export const waitForRequest = <T>(
    p: Promise<T>,
    timeoutSeconds: number,
    timeoutMessage?: string,
): Promise<T | void> => {
    const timeoutPromise: Promise<void> = new Promise(async (_, reject) => {
        await sleep(timeoutSeconds * 1000);
        reject(timeoutMessage || 'Request time out!');
    });
    return Promise.race([p, timeoutPromise]);
};

export const isFeatureEnabled = (feature: FeatureKey, features: Features | null): boolean => {
    return Boolean(features !== null && features[feature] && features[feature].enabled);
};

export const removeUndefined = <T extends object>(obj: T): Partial<T> => {
    const cleanObj: Partial<T> = {};
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined) {
            cleanObj[key] = obj[key];
        }
    }
    return cleanObj;
};

export const getStandardDateText = (args: {
    timezone: string;
    targetDate: Date;
    prefix?: string;
    pastPrefix?: string;
    referenceDate?: Date;
}): string => {
    /**
     * < 0 hrs : <past_prefix?> DD MMM HH:MM AM/PM 
     * < 24 hrs : <prefix> In <hours> hours (e.g. In 2 hours)
     * > 24 hrs < ? : <prefix> Tomorrow by <TIME> (e.g. Tomorrow by 2:00 PM)
     *  default: <prefix> DD MMM HH:MM AM/PM 
     */
    const { timezone, targetDate, prefix, pastPrefix, referenceDate } = args;
    const refMoment = momentTz(referenceDate).tz(timezone);
    const targetMoment = momentTz(targetDate).tz(timezone);
    const minuteDifference = targetMoment.diff(refMoment, 'minutes');
    let dueDateString = '';
    if (minuteDifference < 0) {
        dueDateString = `${pastPrefix ? pastPrefix : ''} ${targetMoment.format('DD MMM h:mm A')}`;
    } else if (minuteDifference < (60 * 48)) {
        dueDateString = `${prefix ? prefix : ''} ${targetMoment.calendar(refMoment)}`;
    } else {
        dueDateString = `${prefix ? prefix : ''} by ${targetMoment.format('DD MMM h:mm A')}`;
    }
    dueDateString = dueDateString.replace(' at ', ' by ');
    return dueDateString;
};

export const dueDateTooltipString = (completeByTimestamp?: Date | null): string => {
    const timezone = momentTz.tz.guess();
    // Due Date: Monday, 20 JUL 2024 by 7:20pm MST
    return completeByTimestamp ?
        `Due Date: ${momentTz(completeByTimestamp).tz(timezone).format('dddd, D MMM YYYY by h:mma z')}`
        : 'Click to add the date and time you would like this to be completed by.';
};


/*
    Replace all the instanaces of integers in a string with one less than that integer.
    For example "1,2,3,4,5,6,7" becomes "0,1,2,3,4,5,6"
    Or "1-7" becomes "0-6"
    works by finding all instances of integers in the string (denoted by \b\d+\b),
    and replacing each match with one less than the matched integer.
    The \b word boundary ensures we're matching whole numbers and not digits within larger numbers.
    The g flag ensures all instances are replaced, not just the first one.
*/
export const decrementIntegers = (str: string): string => {
    return str.replace(/\b\d+\b/g, match => (parseInt(match, 10) - 1).toString());
};

export const getPaymentMethodLabel = (paymentMethod: PaymentMethod | null, paymentMode: PaymentMode | null): string => {
    if (paymentMethod === PaymentMethod.cash) {
        return 'Cash';
    } else if (paymentMethod === PaymentMethod.check) {
        return 'Check';
    } else if (paymentMethod === PaymentMethod.card) {
        return 'Card Reader';
    } else if (paymentMethod === PaymentMethod.insurance) {
        return 'Insurance';
    } else if (paymentMethod === PaymentMethod.other) {
        return 'Other Method';
    } else if (paymentMethod === PaymentMethod.online) {
        switch (paymentMode) { 
            case PaymentMode.IN_PERSON:
                return 'Enter Card';
            case PaymentMode.REMOTE:
                return 'Remote Credit';
            case PaymentMode.GUEST:
                return 'Web Payment';
            default:
                return 'Card Payment';
        }
    } else if (paymentMethod === PaymentMethod.plaid) {
        return 'Remote ACH Transfer';
    } else {
        return '';
    }
};
