import { find } from 'lodash';
import {
    getFromAPI,
    putToAPI,
    postToAPI,
    deleteFromAPI,
    patchAPI,
    advancedAPIRequest,
} from '..';

import { registerAppError, handleException } from '../errors';

import {
    ProductUX,
    ProductRequest,
    ProductManufacturerRecord,
    ProductManufacturerRequest,
    ProductTagRecord,
    ProductPackageUX,
    ProductPackageRequest,
    ProductPatchRequest,
    ContractOptionsUX,
    ProductTaxRateRequest,
    ProductTaxRateUX,
    ProductCategory,
    ContractDisclaimerRequest,
    ContractDisclaimerUX,
    ByProductCategory,
    ProductRankRequest,
    ProductSummary,
    DeleteFHProductResponse,
    PackageSummary,
    CloneGPLCheckResult,
    ProductCategoryEnum,
    ProductCategoryDisplayLookup,
    DeleteGPLCheckResult,
    ProductBulkAction,
    ProductBulkRequest,
    ProductRecord,
} from '../../shared/types';
import { setAppSnackbar, setSnackbarSuccess } from '../AppSnackbar.action';
import { AppDispatch } from '../../store';
import { log } from '../../logger';

const productSnackbarSuccess = (msg: string) => setSnackbarSuccess(msg, undefined, 2000);

/// / ------>  Action Creators  <------ \\\\

// UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS
export const UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS = 'UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS';

interface UpdateFuneralHomeFeaturedProducts {
    type: typeof UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS;
    products: ProductUX[];
}

function updateFhFeaturedProducts(products: ProductUX[]): UpdateFuneralHomeFeaturedProducts {
    return {
        type: UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS,
        products,
    };
}

// FUNERAL_HOME_PRODUCTS_LOADED
export const FUNERAL_HOME_PRODUCTS_LOADED = 'FUNERAL_HOME_PRODUCTS_LOADED';

interface FuneralHomeProductsLoaded {
    type: typeof FUNERAL_HOME_PRODUCTS_LOADED;
    products: ProductUX[];
}

function funeralHomeProductsLoaded(products: ProductUX[]): FuneralHomeProductsLoaded {
    return {
        type: FUNERAL_HOME_PRODUCTS_LOADED,
        products,
    };
}

// FUNERAL_HOME_PRODUCTS_LOADING
export const FUNERAL_HOME_PRODUCTS_LOADING = 'FUNERAL_HOME_PRODUCTS_LOADING';

interface FuneralHomeProductsLoading {
    type: typeof FUNERAL_HOME_PRODUCTS_LOADING;
}

function funeralHomeProductsLoading(): FuneralHomeProductsLoading {
    return {
        type: FUNERAL_HOME_PRODUCTS_LOADING,
    };
}

// FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING
export const FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING = 'FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING';

interface FuneralHomeProductSummariesLoading {
    type: typeof FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING;
}

function funeralHomeProductSummariesLoading(): FuneralHomeProductSummariesLoading {
    return {
        type: FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING,
    };
}

// FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED
export const FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED = 'FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED';

interface FuneralHomeProductSummariesLoaded {
    type: typeof FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED;
    summaries: ProductSummary[];
}

function funeralHomeProductSummariesLoaded(summaries: ProductSummary[]): FuneralHomeProductSummariesLoaded {
    return {
        type: FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED,
        summaries,
    };
}

// SET_FUNERAL_HOME_PRODUCT
export const SET_FUNERAL_HOME_PRODUCT = 'SET_FUNERAL_HOME_PRODUCT';
export type SET_FUNERAL_HOME_PRODUCT = typeof SET_FUNERAL_HOME_PRODUCT;

interface SetFuneralHomeProduct {
    type: typeof SET_FUNERAL_HOME_PRODUCT;
    productId: number;
    product: ProductUX;
}

function setFuneralHomeProduct(productId: number, product: ProductUX): SetFuneralHomeProduct {
    return {
        type: SET_FUNERAL_HOME_PRODUCT,
        productId,
        product,
    };
}

// ADD_FUNERAL_HOME_PRODUCT
export const ADD_FUNERAL_HOME_PRODUCT = 'ADD_FUNERAL_HOME_PRODUCT';
export type ADD_FUNERAL_HOME_PRODUCT = typeof ADD_FUNERAL_HOME_PRODUCT;

interface AddFuneralHomeProduct {
    type: ADD_FUNERAL_HOME_PRODUCT;
    product: ProductUX;
}

function addFuneralHomeProduct(product: ProductUX): AddFuneralHomeProduct {
    return {
        type: ADD_FUNERAL_HOME_PRODUCT,
        product,
    };
}

// UPDATE_FUNERAL_HOME_PRODUCT
export const UPDATE_FUNERAL_HOME_PRODUCT = 'UPDATE_FUNERAL_HOME_PRODUCT';
export type UPDATE_FUNERAL_HOME_PRODUCT = typeof UPDATE_FUNERAL_HOME_PRODUCT;

interface UpdateFuneralHomeProduct {
    type: UPDATE_FUNERAL_HOME_PRODUCT;
    productId: number;
    changes: ProductPatchRequest;
}

function updateFuneralHomeProductInStore(productId: number, changes: ProductPatchRequest): UpdateFuneralHomeProduct {
    return {
        type: UPDATE_FUNERAL_HOME_PRODUCT,
        productId,
        changes,
    };
}

// BULK_ADD_FUNERAL_HOME_PRODUCT
export const BULK_ADD_FUNERAL_HOME_PRODUCT = 'BULK_ADD_FUNERAL_HOME_PRODUCT';
export type BULK_ADD_FUNERAL_HOME_PRODUCT = typeof BULK_ADD_FUNERAL_HOME_PRODUCT;

interface BulkAddFuneralHomeProducts {
    type: BULK_ADD_FUNERAL_HOME_PRODUCT;
    products: ProductUX[];
}

function bulkAddFuneralHomeProducts(products: ProductUX[]): BulkAddFuneralHomeProducts {
    return {
        type: BULK_ADD_FUNERAL_HOME_PRODUCT,
        products,
    };
}

// BULK_UPDATE_FUNERAL_HOME_PRODUCT
export const BULK_UPDATE_FUNERAL_HOME_PRODUCT = 'BULK_UPDATE_FUNERAL_HOME_PRODUCT';
export type BULK_UPDATE_FUNERAL_HOME_PRODUCT  = typeof BULK_UPDATE_FUNERAL_HOME_PRODUCT;
interface BulkUpdateFHProducts {
    type: BULK_UPDATE_FUNERAL_HOME_PRODUCT;
    updatedProducts: ProductUX[];
}

function bulkUpdateFHProducts(updatedProducts: ProductUX[]): BulkUpdateFHProducts {
    return {
        type: 'BULK_UPDATE_FUNERAL_HOME_PRODUCT',
        updatedProducts,
    };
}

// UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS
export const UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS = 'UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS';

interface UpdatedFuneralHomeCategoryProducts {
    type: typeof UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS;
    category: ProductCategory;
    changes: ProductPatchRequest;
}

function updatedFuneralHomeCategoryProducts(
    category: ProductCategory,
    changes: ProductPatchRequest,
): UpdatedFuneralHomeCategoryProducts {
    return {
        type: UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS,
        category,
        changes,
    };
}

// REMOVE_FUNERAL_HOME_PRODUCT
export const REMOVE_FUNERAL_HOME_PRODUCT = 'REMOVE_FUNERAL_HOME_PRODUCT';
export type REMOVE_FUNERAL_HOME_PRODUCT = typeof REMOVE_FUNERAL_HOME_PRODUCT;

interface RemoveFuneralHomeProduct {
    type: REMOVE_FUNERAL_HOME_PRODUCT;
    productId: number;
}

function removeFuneralHomeProduct(productId: number): RemoveFuneralHomeProduct {
    return {
        type: REMOVE_FUNERAL_HOME_PRODUCT,
        productId,
    };
}

// SET_PRODUCT_MANUFACTURERS
export const SET_PRODUCT_MANUFACTURERS = 'SET_PRODUCT_MANUFACTURERS';
export type SET_PRODUCT_MANUFACTURERS = typeof SET_PRODUCT_MANUFACTURERS;

interface SetProductManufacturers {
    type: SET_PRODUCT_MANUFACTURERS;
    manufacturers: ProductManufacturerRecord[];
}

function setManufacturers(manufacturers: ProductManufacturerRecord[]): SetProductManufacturers {
    return {
        type: SET_PRODUCT_MANUFACTURERS,
        manufacturers,
    };
}

// SET_PRODUCT_MANUFACTURER
export const SET_PRODUCT_MANUFACTURER = 'SET_PRODUCT_MANUFACTURER';
export type SET_PRODUCT_MANUFACTURER = typeof SET_PRODUCT_MANUFACTURER;

interface SetProductManufacturer {
    type: SET_PRODUCT_MANUFACTURER;
    manufacturerId: number;
    manufacturer: ProductManufacturerRequest;
}

function setManufacturer(manufacturerId: number, manufacturer: ProductManufacturerRequest): SetProductManufacturer {
    return {
        type: SET_PRODUCT_MANUFACTURER,
        manufacturerId,
        manufacturer,
    };
}

// SET_CONTRACT_OPTIONS
export const SET_CONTRACT_OPTIONS = 'SET_CONTRACT_OPTIONS';
export type SET_CONTRACT_OPTIONS = typeof SET_CONTRACT_OPTIONS;

interface SetContractOptions {
    type: SET_CONTRACT_OPTIONS;
    options: ContractOptionsUX;
}

function setContractOptions(options: ContractOptionsUX): SetContractOptions {
    return {
        type: SET_CONTRACT_OPTIONS,
        options,
    };
}

// UPDATE_CONTRACT_OPTIONS
export const UPDATE_CONTRACT_OPTIONS = 'UPDATE_CONTRACT_OPTIONS';
export type UPDATE_CONTRACT_OPTIONS = typeof UPDATE_CONTRACT_OPTIONS;

interface UpdateContractOptions {
    type: UPDATE_CONTRACT_OPTIONS;
    options: Partial<ContractOptionsUX>;
}

function updateContractOptionsInStore(options: Partial<ContractOptionsUX>): UpdateContractOptions {
    return {
        type: UPDATE_CONTRACT_OPTIONS,
        options,
    };
}

// SET_PRODUCT_TAGS
export const SET_PRODUCT_TAGS = 'SET_PRODUCT_TAGS';
export type SET_PRODUCT_TAGS = typeof SET_PRODUCT_TAGS;

interface SetProductTags {
    type: SET_PRODUCT_TAGS;
    tags: ProductTagRecord[];
}

function setTags(tags: ProductTagRecord[]): SetProductTags {
    return {
        type: SET_PRODUCT_TAGS,
        tags,
    };
}

// ADD_PRODUCT_TAG
export const ADD_PRODUCT_TAG = 'ADD_PRODUCT_TAG';
export type ADD_PRODUCT_TAG = typeof ADD_PRODUCT_TAG;

interface AddProductTag {
    type: ADD_PRODUCT_TAG;
    tag: ProductTagRecord;
}

function addTag(tag: ProductTagRecord): AddProductTag {
    return {
        type: ADD_PRODUCT_TAG,
        tag,
    };
}

// REMOVE_PRODUCT_TAG
export const REMOVE_PRODUCT_TAG = 'REMOVE_PRODUCT_TAG';
export type REMOVE_PRODUCT_TAG = typeof REMOVE_PRODUCT_TAG;

interface RemoveProductTag {
    type: REMOVE_PRODUCT_TAG;
    tag: ProductTagRecord;
}

function removeTag(tag: ProductTagRecord): RemoveProductTag {
    return {
        type: REMOVE_PRODUCT_TAG,
        tag,
    };
}

// SET_PRODUCT_TAX_RATES
export const SET_PRODUCT_TAX_RATES = 'SET_PRODUCT_TAX_RATES';
export type SET_PRODUCT_TAX_RATES = typeof SET_PRODUCT_TAX_RATES;

interface SetProductTaxRates {
    type: SET_PRODUCT_TAX_RATES;
    taxRates: ProductTaxRateUX[];
}

function setTaxRates(taxRates: ProductTaxRateUX[]): SetProductTaxRates {
    return {
        type: SET_PRODUCT_TAX_RATES,
        taxRates,
    };
}

// SET_PRODUCT_TAX_RATE
export const SET_PRODUCT_TAX_RATE = 'SET_PRODUCT_TAX_RATE';
export type SET_PRODUCT_TAX_RATE = typeof SET_PRODUCT_TAX_RATE;

interface SetProductTaxRate {
    type: SET_PRODUCT_TAX_RATE;
    taxRateId: number;
    taxRate: ProductTaxRateRequest;
}

function setTaxRate(taxRateId: number, taxRate: ProductTaxRateRequest): SetProductTaxRate {
    return {
        type: SET_PRODUCT_TAX_RATE,
        taxRateId,
        taxRate,
    };
}

// SET_PRODUCT_PACKAGES
export const SET_PRODUCT_PACKAGES = 'SET_PRODUCT_PACKAGES';
export type SET_PRODUCT_PACKAGES = typeof SET_PRODUCT_PACKAGES;

interface SetProductPackages {
    type: SET_PRODUCT_PACKAGES;
    packages: ProductPackageUX[];
}

function setPackages(packages: ProductPackageUX[]): SetProductPackages {
    return {
        type: SET_PRODUCT_PACKAGES,
        packages,
    };
}

// REMOVE_PRODUCT_PACKAGE
export const REMOVE_PRODUCT_PACKAGE = 'REMOVE_PRODUCT_PACKAGE';
export type REMOVE_PRODUCT_PACKAGE = typeof REMOVE_PRODUCT_PACKAGE;

interface RemoveProductPackage {
    type: REMOVE_PRODUCT_PACKAGE;
    packageId: number;
}

function removePackage(packageId: number): RemoveProductPackage {
    return {
        type: REMOVE_PRODUCT_PACKAGE,
        packageId,
    };
}

// SET_CONTRACT_DISCLAIMERS
export const SET_CONTRACT_DISCLAIMERS = 'SET_CONTRACT_DISCLAIMERS';
export type SET_CONTRACT_DISCLAIMERS = typeof SET_CONTRACT_DISCLAIMERS;

interface SetContractDisclaimers {
    type: SET_CONTRACT_DISCLAIMERS;
    disclaimers: ContractDisclaimerUX[];
}

function setContractDisclaimers(disclaimers: ContractDisclaimerUX[]): SetContractDisclaimers {
    return {
        type: SET_CONTRACT_DISCLAIMERS,
        disclaimers,
    };
}


/// / ------>  API Interface  <------ \\\\

// ------> Product <------ \\

export function loadProductsForFuneralHome(
    funeralHomeId: number,
    categories: ProductCategory[],
    isPublic: boolean,
) {
    return async (dispatch: AppDispatch): Promise<ProductUX[] | null> => {

        if (categories.length === 0) {
            log.warn('No categories passed to loadProductsForFuneralHome', { categories, funeralHomeId, isPublic });
            return null;
        }

        dispatch(funeralHomeProductsLoading());
        const categoriesQuery = encodeURI(categories.join(','));
        const resource = `${isPublic ? 'app/' : ''}funeralhome/${funeralHomeId}/product/?categories=${categoriesQuery}`;
        const products = await getFromAPI<ProductUX[]>(resource, dispatch);
        if (products) {
            dispatch(funeralHomeProductsLoaded(products));
            return products;
        }
        dispatch(registerAppError('Unable to load products.'));
        return null;
    };
}

export function loadAllProductSummariesForFuneralHome(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductSummary[] | null> => {
        dispatch(funeralHomeProductSummariesLoading());
        const summaries = await getFromAPI<ProductSummary[]>(
            `funeralhome/${funeralHomeId}/product/summary/`, dispatch
        );
        if (summaries) {
            dispatch(funeralHomeProductSummariesLoaded(summaries));
            return summaries;
        }
        dispatch(registerAppError('Unable to load products.'));
        return null;
    };
}

export function getFuneralHomeProduct(productId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        const product = await getFromAPI<ProductUX>(
            `funeralhome/${funeralHomeId}/product/${productId}`, dispatch
        );
        if (product) {
            dispatch(setFuneralHomeProduct(productId, product));
            return product;
        }
        dispatch(registerAppError('Unable to load product.'));
        return null;
    };
}

export function createFuneralHomeProduct(product: ProductRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        try {
            ProductRequest.fromRequest(product);
        } catch (ex) {
            log.warn('Failed to validate ProductRequest:', { product, ex });
            return null;
        }
        const createdProduct = await postToAPI<ProductUX>(
            `funeralhome/${funeralHomeId}/product/`, { product }, dispatch
        );
        if (createdProduct) {
            dispatch(addFuneralHomeProduct(createdProduct));
            dispatch(productSnackbarSuccess('Product has been created'));
            return createdProduct;
        }
        dispatch(registerAppError('Unable to add product.'));
        return null;
    };
}

export function cloneGlobalProduct(globalProductId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        const clonedProduct = await postToAPI<ProductUX>(
            `api/product/${globalProductId}/clone`, { funeral_home_id: funeralHomeId }, dispatch
        );
        if (clonedProduct) {
            dispatch(addFuneralHomeProduct(clonedProduct));
            return clonedProduct;
        }
        dispatch(registerAppError('Unable to add product.'));
        return null;
    };
}

export function cloneGlobalProducts(globalProductIds: number[], funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX[] | null> => {
        const expectedProductCount = globalProductIds.length;
        const clonedProducts = await postToAPI<ProductUX[]>(
            `api/product/clone`, { funeral_home_id: funeralHomeId, global_product_ids: globalProductIds }, dispatch
        );
        if (clonedProducts) {
            const updatedProductCount = clonedProducts.length;
            dispatch(bulkAddFuneralHomeProducts(clonedProducts));
                dispatch(
                    productSnackbarSuccess(`${updatedProductCount}/${expectedProductCount} Products have been cloned`)
                );
            return clonedProducts;
        }
        dispatch(registerAppError(`Failed to clone ${expectedProductCount} products.`));
        return null;
    };
}

export function updateFuneralHomeProduct(productId: number, product: ProductRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        try {
            ProductRequest.fromRequest(product);
        } catch (ex) {
            log.warn('Failed to validate ProductRequest:', { product, ex });
            return null;
        }
        dispatch(updateFuneralHomeProductInStore(productId, product));
        const updatedProduct = await putToAPI<ProductUX>(
            `funeralhome/${funeralHomeId}/product/${productId}`, { product }, dispatch
        );
        if (updatedProduct) {
            dispatch(setFuneralHomeProduct(productId, updatedProduct));
            dispatch(productSnackbarSuccess('Product has been updated'));
            return updatedProduct;
        }
        dispatch(registerAppError('Unable to update product.'));
        return null;
    };
}

export function patchFuneralHomeProduct(productId: number, changes: ProductPatchRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductUX | null> => {
        try {
            ProductPatchRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate ProductPatchRequest:', { changes, ex });
            return null;
        }
        dispatch(updateFuneralHomeProductInStore(productId, changes));
        const updatedProduct = await patchAPI<ProductUX>(
            `funeralhome/${funeralHomeId}/product/${productId}`,
            { product: changes },
            dispatch,
        );
        if (updatedProduct) {
            dispatch(setFuneralHomeProduct(productId, updatedProduct));
            dispatch(productSnackbarSuccess('Product has been updated'));
            return updatedProduct;
        }
        dispatch(registerAppError('Unable to update product.'));
        return null;
    };
}

export function updateFuneralHomeFeaturedProducts(
    featuredProductIds: (number | null)[],
    funeralHomeId: number,
    category: ProductCategory,
) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const products = await postToAPI<ProductUX[]>(
            `funeralhome/${funeralHomeId}/featuredproducts/`,
            {
                featured: featuredProductIds,
                category,
            },
            dispatch,
        );
        if (products) {
            dispatch(updateFhFeaturedProducts(products));
            return true;
        }
        dispatch(registerAppError('Unable to update product.'));
        return false;
    };
}

export function updateFuneralHomeProductsInCategory(
    changes: ProductPatchRequest,
    category: ProductCategory,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<void> => {
        try {
            ProductPatchRequest.fromRequest(changes);
        } catch (ex) {
            log.warn('Failed to validate ProductPatchRequest', { changes, ex });
            return;
        }

        const result = await patchAPI<{ success: true }>(
            `funeralhome/${funeralHomeId}/product/category/${category}`,
            { changes },
            dispatch,
        );
        if (result) {
            dispatch(updatedFuneralHomeCategoryProducts(category, changes));
        } else {
            dispatch(registerAppError(`Failed to update ${category} products.`));
        }
    };
}

export function updateFuneralHomeProductRanks(
    productIdsByCategory: ByProductCategory<number>,
    funeralHomeId: number,
) {
    return async (dispatch: AppDispatch): Promise<ProductSummary[] | null> => {
        try {
            ProductRankRequest.fromRequest(productIdsByCategory);
        } catch (ex) {
            log.warn('Failed to validate ProductRankRequest:', { productIdsByCategory, ex });
            return null;
        }

        const summaries = await postToAPI<ProductSummary[]>(
            `funeralhome/${funeralHomeId}/product/rank`,
            productIdsByCategory,
            dispatch,
        );
        if (summaries) {
            dispatch(funeralHomeProductSummariesLoaded(summaries));
            return summaries;
        }
        dispatch(registerAppError('Unable to update products order.'));
        return null;
    };
}

export function deleteFuneralHomeProduct(productId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<PackageSummary[] | null> => {
        const route = `funeralhome/${funeralHomeId}/product/${productId}`;
        const response = await advancedAPIRequest(route, 'DELETE', {}, dispatch);
        try {
            const body: DeleteFHProductResponse | null = response ? await response.json() : null;
            if (body && response) {
                if (response.status === 200 && body.deletedProduct) {
                    dispatch(removeFuneralHomeProduct(productId));
                    dispatch(productSnackbarSuccess('Product has been deleted'));
                    return [];
                } else if (response.status === 409 && body.packagesUsingProduct.length > 0) {
                    return body.packagesUsingProduct;
                } else {
                    log.warn('Invalid state of Delete FuneralHome Product Response', { body });
                    return null;
                }
            }
            dispatch(registerAppError('Unable to remove product.', { sendToSentry: true }));
            return null;
        } catch (ex) {
            dispatch(handleException({ ex, showSnackbar: false, sendToSentry: true }));
            return null;
        }
    };
}

// ------> ProductManufacturer <------ \\
export function loadProductManufacturers() {
    return async (dispatch: AppDispatch): Promise<ProductManufacturerRecord[] | null> => {
        const manufacturers = await getFromAPI<ProductManufacturerRecord[]>('api/productmanufacturer/', dispatch);
        if (manufacturers) {
            dispatch(setManufacturers(manufacturers));
            return manufacturers;
        }
        dispatch(registerAppError('Unable to load manufacturers.'));
        return null;
    };
}

export function createProductManufacturer(manufacturer: ProductManufacturerRequest) {
    return async (dispatch: AppDispatch): Promise<ProductManufacturerRecord | null> => {
        try {
            ProductManufacturerRequest.fromRequest(manufacturer);
        } catch (ex) {
            log.warn('Failed to validate ProductManufacturerRequest:', { manufacturer, ex });
            return null;
        }
        const manufacturers = await postToAPI<ProductManufacturerRecord[]>(
            'api/productmanufacturer/', { manufacturer }, dispatch
        );
        if (manufacturers) {
            dispatch(setManufacturers(manufacturers));
            const createdManufacturer = find(manufacturers, { name: manufacturer.name });
            if (createdManufacturer) {
                dispatch(productSnackbarSuccess('Manufacturer created'));
                return createdManufacturer;
            }
        }
        dispatch(registerAppError('Unable to create manufacturer.'));
        return null;
    };
}

export function updateProductManufacturer(manufacturerId: number, manufacturer: ProductManufacturerRequest) {
    return async (dispatch: AppDispatch): Promise<ProductManufacturerRecord | null> => {
        try {
            ProductManufacturerRequest.fromRequest(manufacturer);
        } catch (ex) {
            log.warn('Failed to validate ProductManufacturerRequest:', { manufacturer, ex });
            return null;
        }
        dispatch(setManufacturer(manufacturerId, manufacturer));
        const manufacturers = await putToAPI<ProductManufacturerRecord[]>(
            `api/productmanufacturer/${manufacturerId}`, { manufacturer }, dispatch
        );
        if (manufacturers) {
            dispatch(setManufacturers(manufacturers));
            const updatedManufacturer = find(manufacturers, { id: manufacturerId });
            if (updatedManufacturer) {
                dispatch(productSnackbarSuccess('Manufacturer updated'));
                return updatedManufacturer;
            }
        }
        dispatch(registerAppError('Unable to update manufacturer.'));
        return null;
    };
}

// ------> ProductTag <------ \\
export function loadProductTags() {
    return async (dispatch: AppDispatch): Promise<ProductTagRecord[] | null> => {
        const tags = await getFromAPI<ProductTagRecord[]>('api/producttag/', dispatch);
        if (tags) {
            dispatch(setTags(tags));
            return tags;
        }
        dispatch(registerAppError('Unable to load product tags.'));
        return null;
    };
}

export function createProductTag(tag: ProductTagRecord) {
    return async (dispatch: AppDispatch): Promise<ProductTagRecord | null> => {
        try {
            ProductTagRecord.fromRequest(tag);
        } catch (ex) {
            log.warn('Failed to validate ProductTagRecord:', { tag, ex });
            return null;
        }
        dispatch(addTag(tag));
        const category = encodeURIComponent(tag.category);
        const type = encodeURIComponent(tag.name);
        const value = encodeURIComponent(tag.value);
        const tags = await putToAPI<ProductTagRecord[]>(
            `api/producttag/category/${category}/name/${type}/value/${value}`, {}, dispatch
        );
        if (tags) {
            dispatch(setTags(tags));
            const createdTag = find(tags, tag);
            dispatch(productSnackbarSuccess('Product tag created'));
            if (createdTag) {
                return createdTag;
            }
        }
        dispatch(registerAppError('Unable to create product tag.'));
        return null;
    };
}

export function deleteProductTag(tag: ProductTagRecord) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        try {
            ProductTagRecord.fromRequest(tag);
        } catch (ex) {
            log.warn('Failed to validate ProductTagRecord:', { tag, ex });
            return false;
        }
        dispatch(removeTag(tag));
        const category = encodeURIComponent(tag.category);
        const type = encodeURIComponent(tag.name);
        const value = encodeURIComponent(tag.value);
        const tags = await deleteFromAPI<ProductTagRecord[]>(
            `api/producttag/category/${category}/name/${type}/value/${value}`, dispatch
        );
        if (tags) {
            dispatch(setTags(tags));
            const removedTag = find(tags, tag);
            if (!removedTag) {
                dispatch(productSnackbarSuccess('Product tag deleted'));
                return true;
            }
        }
        dispatch(registerAppError('Unable to remove product tag.'));
        return false;
    };
}

// ------> ProductTaxRate <------ \\
export function loadProductTaxRates() {
    return async (dispatch: AppDispatch): Promise<ProductTaxRateUX[] | null> => {
        const taxRates = await getFromAPI<ProductTaxRateUX[]>('api/producttaxrate/', dispatch);
        if (taxRates) {
            dispatch(setTaxRates(taxRates));
            return taxRates;
        }
        dispatch(registerAppError('Unable to load tax rates.'));
        return null;
    };
}

export function createProductTaxRate(taxRate: ProductTaxRateRequest) {
    return async (dispatch: AppDispatch): Promise<ProductTaxRateUX | null> => {
        try {
            ProductTaxRateRequest.fromRequest(taxRate);
        } catch (ex) {
            log.warn('Failed to validate ProductTaxRateRequest:', { taxRate, ex });
            return null;
        }
        const taxRates = await postToAPI<ProductTaxRateUX[]>(
            'api/producttaxrate/', { taxRate }, dispatch
        );
        if (taxRates) {
            dispatch(setTaxRates(taxRates));
            const createdTaxRate = taxRates.find((tr) => tr.name === taxRate.name);
            if (createdTaxRate) {
                dispatch(productSnackbarSuccess('Tax rate created'));
                return createdTaxRate;
            }
        }
        dispatch(registerAppError('Unable to create tax rate.'));
        return null;
    };
}

export function updateProductTaxRate(taxRateId: number, taxRate: ProductTaxRateRequest) {
    return async (dispatch: AppDispatch): Promise<ProductTaxRateUX | null> => {
        try {
            ProductTaxRateRequest.fromRequest(taxRate);
        } catch (ex) {
            log.warn('Failed to validate ProductTaxRateRequest:', { taxRate, ex });
            return null;
        }
        dispatch(setTaxRate(taxRateId, taxRate));
        const taxRates = await putToAPI<ProductTaxRateUX[]>(
            `api/producttaxrate/${taxRateId}`, { taxRate }, dispatch
        );
        if (taxRates) {
            dispatch(setTaxRates(taxRates));
            const updatedTaxRate = find(taxRates, { id: taxRateId });
            if (updatedTaxRate) {
                dispatch(productSnackbarSuccess('Tax rate updated'));
                return updatedTaxRate;
            }
        }
        dispatch(registerAppError('Unable to update tax rate.'));
        return null;
    };
}

// ------> ProductPackage <------ \\
export function loadProductPackages(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductPackageUX[] | null> => {
        const packages = await getFromAPI<ProductPackageUX[]>(
            `funeralhome/${funeralHomeId}/productpackage/`, dispatch
        );
        if (packages) {
            dispatch(setPackages(packages));
            return packages;
        }
        dispatch(registerAppError('Unable to load packages.'));
        return null;
    };
}

export function createProductPackage(packageRequest: ProductPackageRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductPackageUX[] | null> => {
        try {
            ProductPackageRequest.fromRequest(packageRequest);
        } catch (ex) {
            log.warn('Failed to validate ProductPackageRequest:', { packageRequest, ex });
            return null;
        }
        const packages = await postToAPI<ProductPackageUX[]>(
            `funeralhome/${funeralHomeId}/productpackage/`, { packageRequest }, dispatch
        );
        if (packages) {
            dispatch(setPackages(packages));
            dispatch(productSnackbarSuccess('Package created'));
            return packages;
        }
        dispatch(registerAppError('Unable to create package.'));
        return null;
    };
}

export function updateProductPackage(packageId: number, packageRequest: ProductPackageRequest, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductPackageUX | null> => {
        try {
            ProductPackageRequest.fromRequest(packageRequest);
        } catch (ex) {
            log.warn('Failed to validate ProductPackageRequest:', { packageRequest, ex });
            return null;
        }
        const packages = await putToAPI<ProductPackageUX[]>(
            `funeralhome/${funeralHomeId}/productpackage/${packageId}`, { packageRequest }, dispatch
        );
        if (packages) {
            dispatch(setPackages(packages));
            const updatedPackage = find(packages, { id: packageId });
            if (updatedPackage) {
                dispatch(productSnackbarSuccess('Package updated'));
                return updatedPackage;
            }
        }
        dispatch(registerAppError('Unable to update package.'));
        return null;
    };
}

export function updateProductPackageRanks(packageIds: number[], funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ProductPackageUX[] | null> => {

        const packages = await postToAPI<ProductPackageUX[]>(
            `funeralhome/${funeralHomeId}/productpackage/rank`,
            { packageIds },
            dispatch,
        );
        if (packages) {
            dispatch(setPackages(packages));
            return packages;
        }
        dispatch(registerAppError('Unable to update package order.'));
        return null;
    };
}

export function deleteProductPackage(packageId: number, funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        dispatch(removePackage(packageId));
        const packages = await deleteFromAPI<ProductPackageUX[]>(
            `funeralhome/${funeralHomeId}/productpackage/${packageId}`, dispatch
        );
        if (packages) {
            dispatch(setPackages(packages));
            // make sure it was deleted from the list
            const removedPackage = find(packages, { id: packageId });
            if (!removedPackage || removedPackage.deleted_time !== null) {
                dispatch(productSnackbarSuccess('Package deleted'));
                return true;
            }
        }
        dispatch(registerAppError('Unable to remove package.'));
        return false;
    };
}

// ------> Contract Options <------ \\

export function loadContractOptions(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ContractOptionsUX | null> => {
        const contractOptions = await getFromAPI<ContractOptionsUX>(
            `funeralhome/${funeralHomeId}/contractoptions/`, dispatch
        );
        if (contractOptions) {
            dispatch(setContractOptions(contractOptions));
            return contractOptions;
        }
        dispatch(registerAppError('Unable to load options.'));
        return null;
    };
}

export function updateContractOptions(funeralHomeId: number, options: Partial<ContractOptionsUX>) {
    return async (dispatch: AppDispatch): Promise<ContractOptionsUX | null> => {
        try {
            ContractOptionsUX.fromPatchRequest(options);
        } catch (ex) {
            log.warn('Failed to validate ContractOptionsUX:', { options, ex });
            return null;
        }
        dispatch(updateContractOptionsInStore(options));
        const contractOptions = await patchAPI<ContractOptionsUX>(
            `funeralhome/${funeralHomeId}/contractoptions/`, { options }, dispatch
        );
        if (contractOptions) {
            dispatch(setContractOptions(contractOptions));
            return contractOptions;
        }
        dispatch(registerAppError('Unable to save contract options.'));
        return null;
    };
}

// ------> Contract Disclaimers <------ \\

export function loadContractDisclaimers(funeralHomeId: number) {
    return async (dispatch: AppDispatch): Promise<ContractDisclaimerUX[] | null> => {
        const contractDisclaimers = await getFromAPI<ContractDisclaimerUX[]>(
            `funeralhome/${funeralHomeId}/contractdisclaimer/`, dispatch
        );
        if (contractDisclaimers) {
            dispatch(setContractDisclaimers(contractDisclaimers));
            return contractDisclaimers;
        }
        dispatch(registerAppError('Unable to load contract disclaimers.'));
        return null;
    };
}

export function saveContractDisclaimers(
    funeralHomeId: number,
    disclaimers: ContractDisclaimerRequest[],
    legalText: string | null,
) {
    return async (dispatch: AppDispatch): Promise<ContractDisclaimerUX[] | null> => {
        try {
            disclaimers.map(ContractDisclaimerRequest.fromRequest);
        } catch (ex) {
            log.warn('Failed to validate ContractDisclaimerRequest:', { disclaimers, ex });
            return null;
        }

        interface SaveDisclaimersResponse {
            disclaimers: ContractDisclaimerUX[];
            contractOptions: ContractOptionsUX;
        }
        const response = await putToAPI<SaveDisclaimersResponse>(
            `funeralhome/${funeralHomeId}/contractdisclaimer`, { disclaimers, legalText }, dispatch
        );
        if (response) {
            dispatch(setContractDisclaimers(response.disclaimers));
            dispatch(setContractOptions(response.contractOptions));
            return response.disclaimers;
        }
        dispatch(registerAppError('Unable to save contract disclaimers.'));
        return null;
    };
}

export function cloneGPLCheck(params: {
    funeralHomeId: number;
    targetFuneralHomeId: number;
    category: 'all' | ProductCategoryEnum;
}) {
    return async (dispatch: AppDispatch): Promise<CloneGPLCheckResult | null> => {
        const { funeralHomeId, targetFuneralHomeId, category } = params;
        const response = await getFromAPI<CloneGPLCheckResult>(
            `funeralhome/${funeralHomeId}/clonegpl/${targetFuneralHomeId}/category/${category}/check`, dispatch
        );
        return response;
    };
}

export function cloneGPL(params: {
    funeralHomeId: number;
    targetFuneralHomeId: number;
    category: 'all' | ProductCategoryEnum;
}) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const { funeralHomeId, targetFuneralHomeId, category } = params;
        const response = await postToAPI<{ success: true }>(
            `funeralhome/${funeralHomeId}/clonegpl/${targetFuneralHomeId}/category/${category}`, {}, dispatch
        );
        if (response && response.success) {
            const message = category === 'all'
                ? 'GPL cloned succesfully!'
                : `${ProductCategoryDisplayLookup[category]} section cloned successfully`;
            dispatch(productSnackbarSuccess(message));
            return true;
        } else {
            return false;
        }
    };
}

export function deleteGPLCheck(params: {
    funeralHomeId: number;
    category: 'all' | ProductCategoryEnum;
}) {
    return async (dispatch: AppDispatch): Promise<DeleteGPLCheckResult | null> => {
        const { funeralHomeId, category } = params;
        const response = await getFromAPI<DeleteGPLCheckResult>(
            `funeralhome/${funeralHomeId}/deletegpl/category/${category}/check`, dispatch
        );
        return response;
    };
};

export function deleteGPL(params: {
    funeralHomeId: number;
    category: 'all' | ProductCategoryEnum;
    userId: number;
}) {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        const { funeralHomeId, category } = params;
        const response = await deleteFromAPI<{ success: true }>(
            `funeralhome/${funeralHomeId}/deletegpl/category/${category}`, dispatch
        );
        if (response && response.success) {
            const message = category === 'all'
                ? 'GPL deleted succesfully!'
                : `${ProductCategoryDisplayLookup[category]} section deleted successfully`;
            dispatch(productSnackbarSuccess(message));
            return true;
        } else {
            return false;
        }
    };
};

/**
 * Bulk product updates 
 */
export function bulkProductAction(args: {
    funeralHomeId: number | null;
    productIds: number[];
    bulkAction: ProductBulkAction;
    changes: Partial<ProductRecord>;
}) {
    return async (dispatch: AppDispatch): Promise<ProductUX[]> => {
        const { funeralHomeId, productIds, bulkAction, changes } = args;
        if (!funeralHomeId) {
            log.warn('funeralHomeId is required for bulkEditTaxRate');
            return [];
        }
        let bulkRequest: ProductBulkRequest;
        // Do some validation before sending. 
        switch (bulkAction) {
            case ProductBulkAction.delete_selected_products:
                bulkRequest = {
                    bulkAction: ProductBulkAction.delete_selected_products,
                    actionDetails: {},
                    productIds
                };
                break;

            case ProductBulkAction.edit_tax_rate:
                if (!changes.tax_rate_id) {
                    log.warn('tax_rate_id is required for bulkEditTaxRate');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.edit_tax_rate,
                    actionDetails: {
                        tax_rate_id: changes.tax_rate_id
                    },
                    productIds
                };
                break;

            case ProductBulkAction.show_hide_on_public_gpl:
                if (changes.is_hidden === undefined) {
                    log.warn('is_hidden is required for show_hide_on_public_gpl');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.show_hide_on_public_gpl,
                    actionDetails: {
                        is_hidden: changes.is_hidden
                    },
                    productIds
                };
                break;

            case ProductBulkAction.change_category:
                if (!changes.category) {
                    log.warn('category is required for change_category');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.change_category,
                    actionDetails: {
                        category: changes.category
                    },
                    productIds
                };
                break;
            case ProductBulkAction.show_hide_on_website:
                if (changes.show_on_website === undefined) {
                    log.warn('show_on_website is required for show_hide_on_website');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.show_hide_on_website,
                    actionDetails: {
                        show_on_website: changes.show_on_website
                    },
                    productIds
                };
                break;

            case ProductBulkAction.show_hide_price_on_website:
                if (changes.show_price_on_website === undefined) {
                    log.warn('show_price_on_website is required for show_hide_price_on_website');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.show_hide_price_on_website,
                    actionDetails: {
                        show_price_on_website: changes.show_price_on_website
                    },
                    productIds
                };
                break;

            case ProductBulkAction.edit_unit:
                if (changes.var_increment_units === undefined) {
                    log.warn('var_increment_units is required for edit_unit');
                    return [];
                }
                bulkRequest = {
                    bulkAction: ProductBulkAction.edit_unit,
                    actionDetails: {
                        var_increment_units: changes.var_increment_units || null
                    },
                    productIds
                };
                break;
            case ProductBulkAction.edit_default_unit_quantity:
                if (changes.var_default_quantity === undefined) {
                    log.warn('default_unit_quantity is required for edit_default_unit_quantity');
                    return [];
                }

                bulkRequest = {
                    bulkAction: ProductBulkAction.edit_default_unit_quantity,
                    actionDetails: {
                        var_default_quantity: changes.var_default_quantity
                    },
                    productIds
                };
                break;
            case ProductBulkAction.edit_styling_on_statement:
                if (changes.persistent_contract_text === undefined && changes.bold_contract_text === undefined
                    && changes.underline_contract_text === undefined && changes.indent_contract_text === undefined
                    && changes.display_manufacturer === undefined && changes.display_model_number === undefined
                    && changes.display_tags === undefined && changes.is_always_displayed === undefined
                    && changes.use_na_when_always_displayed === undefined
                ) {
                    log.warn('at least one style is required for edit_styling_on_statement');
                    return [];
                }
                let actionDetails = {};
                if (changes.persistent_contract_text !== undefined) {
                    actionDetails = { ...actionDetails, persistent_contract_text: changes.persistent_contract_text };
                }
                if (changes.bold_contract_text !== undefined) {
                    actionDetails = { ...actionDetails, bold_contract_text: changes.bold_contract_text };
                }
                if (changes.underline_contract_text !== undefined) {
                    actionDetails = { ...actionDetails, underline_contract_text: changes.underline_contract_text };
                }
                if (changes.indent_contract_text !== undefined) {
                    actionDetails = { ...actionDetails, indent_contract_text: changes.indent_contract_text };
                }
                if (changes.display_manufacturer !== undefined) {
                    actionDetails = { ...actionDetails, display_manufacturer: changes.display_manufacturer };
                }
                if (changes.display_model_number !== undefined) {
                    actionDetails = { ...actionDetails, display_model_number: changes.display_model_number };
                }
                if (changes.display_tags !== undefined) {
                    actionDetails = { ...actionDetails, display_tags: changes.display_tags };
                }
                if (changes.is_always_displayed !== undefined) {
                    actionDetails = {
                        ...actionDetails,
                        is_always_displayed: changes.is_always_displayed,
                        use_na_when_always_displayed: Boolean(changes.use_na_when_always_displayed),
                    };
                }

                bulkRequest = {
                    bulkAction: ProductBulkAction.edit_styling_on_statement,
                    actionDetails,
                    productIds,
                };

                break;

            default:
                log.warn('bulkAction not implmented.', { bulkAction });
                return [];
        }
        const response = await patchAPI<{ success: true; modifiedProductList: ProductUX[] }>(
            `funeralhome/${funeralHomeId}/product/bulk/`, { bulkRequest }, dispatch
        );
        if (response && response.success) {
            const updatedProducts = response.modifiedProductList;
            const count = updatedProducts.length;
            const expectedCount = productIds.length;
            dispatch(bulkUpdateFHProducts(updatedProducts));
            dispatch(productSnackbarSuccess(`${count}/${expectedCount} Products updated successfully`));
            return response.modifiedProductList;
        } else {
            const expectedCount = productIds.length;
            dispatch(setAppSnackbar(`Unable to update ${expectedCount} Products`, 'error'));
            return [];
        }
    };
}

export function bulkUpdateProductPrice(args: {
    funeralHomeId: number;
    productIdPriceMap: { productId: number; value: number }[];
    bulkAction: ProductBulkAction;
}) {
    return async (dispatch: AppDispatch): Promise<ProductUX[]> => {
        const { funeralHomeId, productIdPriceMap, bulkAction } = args;
        const productIds = productIdPriceMap.map((item) => item.productId);
        const expectedCount = productIds.length;
        const apiBase = `funeralhome/${funeralHomeId}/product/bulk`;
        const targetField = bulkAction === ProductBulkAction.edit_base_price ? 'base_price'
            : bulkAction === ProductBulkAction.edit_variable_price ? 'var_price'
                : null;
        if (!targetField) {
            log.warn('Invalid bulkAction for bulkUpdateProductPrice', { bulkAction });
            return [];
        }
        const bulkRequest = {
            bulkAction,
            productIdPriceMap,
        };
        const response = await patchAPI<{ success: true; modifiedProductList: ProductUX[] }>(
            `${apiBase}/${targetField}`, { bulkRequest }, dispatch
        );
        // eslint-disable-next-line no-console
        console.log('response', response);
        if (response && response.success) {
            const count = response.modifiedProductList.length;
            const updatedProducts = response.modifiedProductList;
            dispatch(bulkUpdateFHProducts(updatedProducts));
            dispatch(productSnackbarSuccess(`${count}/${expectedCount} Products updated successfully`));
            return response.modifiedProductList;
        } else {
            dispatch(setAppSnackbar(`Unable to update ${expectedCount} Products`, 'error'));
            return [];
        }
    };
}

// End Bulk product updates

export type FHProductAction =
    | UpdateFuneralHomeFeaturedProducts
    | FuneralHomeProductsLoading
    | FuneralHomeProductsLoaded
    | FuneralHomeProductSummariesLoading
    | FuneralHomeProductSummariesLoaded
    | SetFuneralHomeProduct
    | AddFuneralHomeProduct
    | UpdateFuneralHomeProduct
    | UpdatedFuneralHomeCategoryProducts
    | RemoveFuneralHomeProduct
    | SetProductManufacturers
    | SetProductManufacturer
    | SetProductTags
    | AddProductTag
    | RemoveProductTag
    | SetContractOptions
    | UpdateContractOptions
    | SetProductTaxRates
    | SetProductTaxRate
    | SetProductPackages
    | RemoveProductPackage
    | SetContractDisclaimers
    | BulkAddFuneralHomeProducts 
    | BulkUpdateFHProducts 
    ;
