import { map, orderBy, uniqBy } from 'lodash';

import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiRef,
    ValueOptions,
} from '@mui/x-data-grid-premium';
import {
    PricingModelDisplayLookup,
    PricingModelEnum,
    ProductCategoryDisplayLookup,
    ProductCategoryEnum,
    ProductSummary,
} from '../../shared/types';
import { getPhotoUrl } from '../../services';
import GSwitch from '../common/inputElements/GSwitch';
import GDataGrid, { GDataGridProps, RowData } from '../common/GDataGrid/GDataGrid';
import { GDataGridTableColumn } from '../common/GDataGrid/types';
import useFullScreen from '../common/hooks/useFullScreen';
import {
    booleanOperators,
    localeText,
    photosOperators
} from '../family/goodsAndServices/widgets/utils';
import usePriceFilterOperators from '../reports/hooks/usePriceFilterOperators';
import { formatPriceForReports, getGoodsAndServicesCellRenderer } from '../reports/utils';
import useNumericFilterOperators from '../reports/hooks/useNumericFilterOperators';
import useProductTagsFilterOperators from '../reports/hooks/useProductTagsFilterOperators';
import { useMemo } from 'react';

type ProductRow = ProductSummary & { row_id: number };

const transformations = [
    {
        width: 52,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    },
];

interface Props extends Pick<GDataGridProps<RowData>, 'hasChanges' | 'pinnedColumns' | 'onRowSelectionModelChange'> {
    actionColumn: GDataGridTableColumn<ProductSummary & {
        row_id: number;
    }>[];
    availableProducts: ProductSummary[];
    isLoading: boolean;
    children?: JSX.Element;
}

const BaseProductSummaryGrid = (props: Props) => {
    const {
        availableProducts,
        actionColumn,
        isLoading,
        hasChanges,
        pinnedColumns,
        onRowSelectionModelChange,
        children
    } = props;

    const fullScreen = useFullScreen();
    const muiApiRef = useGridApiRef();

    const renderProductTags = (params: GridRenderCellParams<ProductSummary>) => (
        <FormControl
            sx={{
                flexDirection: 'row',
                gap: '10px',
                overflow: 'auto',
            }}
        >
            {map(params.row.tags, (tagValue, tagName) => (
                <Chip key={`${tagName}: ${tagValue}`} label={`${tagName}: ${tagValue}`} onDelete={() => void 0} />
            ))}
        </FormControl>
    );

    const ProductTagsValueOptions: ValueOptions[] = useMemo(() => {
        const valueList: { value: string; label: string }[] = [];

        for (const row of availableProducts) {
            map(row.tags, (tagValue, tagName) => (
                valueList.push({
                    value: `${tagName}: ${tagValue}`,
                    label: `${tagName}: ${tagValue}`,
                })
            ));
        }
        const uniqueValues = uniqBy(valueList, (v) => v.value);
        const uniqueLabels = uniqBy(uniqueValues, (v) => v.label);
        const sortedLabels = orderBy(uniqueLabels, (v) => v.label, ['asc']);

        return sortedLabels;
    }, [availableProducts]);

    const priceOperators = usePriceFilterOperators();
    const numericOperators = useNumericFilterOperators();
    const productTagsFilterOperators = useProductTagsFilterOperators();

    const columns: GDataGridTableColumn<ProductSummary & { row_id: number }>[] = [
        ...actionColumn,
        {
            field: 'name',
            headerName: 'Product Name',
            description: `The name of the product that will display on the Statement when added. 
            The Product Name can be edited per case if the following user-level permission is set: 
            Can Modify Item Name. You can also make this field display on the Statement by using the 
            Display: Product Name product toggle.`,
            minWidth: 200,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <Typography textTransform="capitalize" fontWeight={400}>
                    {row.name}
                </Typography>
            )),
        },
        {
            field: 'photos',
            headerName: 'Product Photos',
            description: `One or more photos can be added to a product which will show in the digital 
            Showroom and in the product details popup. The first photo listed will be the one that shows 
            on the product card in the digital Showroom.`,
            minWidth: 206,
            align: 'center',
            filterOperators: photosOperators,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                row.photos && row.photos.length > 0 && row.photos[0] ?
                    <img
                        src={getPhotoUrl(row.photos[0], transformations)}
                        alt={row.photos[0]}
                        style={{ mixBlendMode: 'color-burn' }}
                    />
                    : <></>
            )),
        },
        {
            field: 'manufacturer_name',
            headerName: 'Product Line Manufacturer',
            description: `Each product can be grouped under a manufacturer. You can also filter by this 
            field in the digital Showroom. You can also make this field display on the Statement by using 
            the Display: Manufacturer product toggle.`,
            minWidth: 280,
        },
        {
            field: 'model_number',
            headerName: 'Model Number',
            description: `The Model Number will be shown on the product details popup. You can also make this 
            number display on the Statement by using the Display: Model Number product toggle.`,
            minWidth: 200,
        },
        {
            field: 'base_price',
            headerName: 'Base Price',
            minWidth: 170,
            type: 'number',
            headerAlign: 'left',
            valueGetter: ({ value }) => Number(value),
            valueFormatter: ({ value }) => formatPriceForReports(value, 'USD'),
            filterOperators: priceOperators,
        },
        {
            field: 'tags',
            headerName: 'Product Tag(s)',
            minWidth: 400,
            type: 'singleSelect',
            filterOperators: productTagsFilterOperators,
            valueOptions: ProductTagsValueOptions,
            renderCell: renderProductTags,
        },
        {
            field: 'category',
            headerName: 'Product Category',
            description: `Each product can be added to one of 10 product categories which will determine 
            where it displays both in the digital Showroom and on the Statement.`,
            minWidth: 220,
            valueFormatter: ({ value }: { value: ProductCategoryEnum }) => ProductCategoryDisplayLookup[value],
        },
        {
            field: 'is_hidden',
            headerName: 'Hidden from digital Showroom?',
            description: `This setting will remove the product from the digital Showroom but will still allow it 
            to be selected from the green button on the Statement page.`,
            minWidth: 310,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch checked={row.is_hidden} onChangeCallBack={(value) => void 0} disabled />
                </FormControl>
            )),
        },
        {
            field: 'pricing_model',
            headerName: 'Pricing Model',
            description: `Each product can have one of five pricing models applied to it which will determine how 
            the price is determined when added to a Statement.`,
            minWidth: 190,
            valueFormatter: ({ value }: { value: PricingModelEnum }) => PricingModelDisplayLookup[value],
        },
        {
            field: 'base_quantity',
            headerName: 'Units with Base Price',
            minWidth: 240,
            type: 'number',
            headerAlign: 'left',
            align: 'right',
            filterOperators: numericOperators,
        },
        {
            field: 'var_price',
            headerName: 'Variable Price',
            minWidth: 194,
            type: 'number',
            headerAlign: 'left',
            filterOperators: priceOperators,
            align: 'right',
            valueGetter: ({ value }) => Number(value),
            valueFormatter: ({ value }) => formatPriceForReports(value, 'USD'),
        },
        {
            field: 'var_increment',
            headerName: 'Variable Increment',
            minWidth: 230,
            type: 'number',
            headerAlign: 'left',
            align: 'right',
            filterOperators: numericOperators,
        },
        {
            field: 'var_increment_units',
            headerName: 'Units',
            minWidth: 150,
        },
        {
            field: 'var_default_quantity',
            headerName: 'Default Unit Amount',
            minWidth: 240,
            type: 'number',
            headerAlign: 'left',
            align: 'right',
            filterOperators: numericOperators,
        },
        {
            field: 'tax_rate_id',
            headerName: 'Taxable',
            minWidth: 150,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch checked={row.tax_rate_id !== null} onChangeCallBack={() => void 0} disabled />
                </FormControl>
            )),
        },
        {
            field: 'bold_contract_text',
            headerName: 'Display: Bold',
            minWidth: 190,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.bold_contract_text}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'underline_contract_text',
            headerName: 'Display: Underline',
            minWidth: 230,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.underline_contract_text}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'indent_contract_text',
            headerName: 'Display: Indent',
            minWidth: 210,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.indent_contract_text}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'display_manufacturer',
            headerName: 'Display: Manufacturer',
            minWidth: 250,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.display_manufacturer}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'display_model_number',
            headerName: 'Display: Model Number',
            minWidth: 260,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.display_model_number}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'display_tags',
            headerName: 'Display: Product Tag(s)',
            minWidth: 250,
            align: 'center',
            filterOperators: booleanOperators,
            valueGetter: ({ value }) => value,
            renderCell: getGoodsAndServicesCellRenderer(({ row }) => (
                <FormControl>
                    <GSwitch
                        checked={row.display_tags}
                        onChangeCallBack={(value) => void 0}
                        disabled
                    />
                </FormControl>
            )),
        },
        {
            field: 'persistent_contract_text',
            headerName: 'Display: Persistent Text',
            minWidth: 260,
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                sx={(theme) => ({
                    width: '100%',
                    padding: '4px',
                    paddingBottom: '12px',
                    '& .MuiDataGrid-main': {
                        borderRadius: '22px !important',
                        maxHeight: '70vh !important',
                        minHeight: '70vh !important',
                        '@media (min-width: 600px)': {
                            maxHeight: '55vh',
                            minHeight: '55vh',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            overflowY: 'unset !important',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                            height: 'auto !important',
                            minHeight: 'auto !important',
                        },
                    },
                    '& .MuiTablePagination-actions': {
                        '& button': {
                            color: theme.palette.primary.main,
                            '&:disabled': {
                                color: theme.palette.secondary.main,
                            }
                        }
                    }
                })}
            >
                <GDataGrid<ProductRow>
                    muiApiRef={muiApiRef}
                    localeText={localeText}
                    pinnedColumns={pinnedColumns ?? { left: fullScreen ? undefined : ['Actions', 'name'] }}
                    data={availableProducts.map((p) => ({ ...p, row_id: p.id }))}
                    hasChanges={hasChanges}
                    loading={isLoading}
                    columns={columns}
                    toolbarSlot={() => (
                        <GridToolbarContainer sx={{ justifyContent: 'flex-start !important' }}>
                            <GridToolbarColumnsButton />
                            <GridToolbarFilterButton />
                            <GridToolbarDensitySelector />
                        </GridToolbarContainer>
                    )}
                    footerText="Products"
                    zIndex={1320}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    disableRowGrouping
                />
            </Grid>

            {children}
        </Grid>
    );
};

export default BaseProductSummaryGrid;
